import React, { useEffect, useMemo, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { getUser } from "../api/UserApi";

const NavBar = () => {
  const [navAnchor, setNavAnchor] = useState(null),
    [loginAnchor, setLoginAnchor] = useState(null),
    isHome = useMatch("/"),
    isLessons = useMatch("/lessons/*"),
    isQuestions = useMatch("/questions/*"),
    isBooks = useMatch("/books/*"),
    isPrayers = useMatch("/prayers/*"),
    isMembers = useMatch("/members/*"),
    defaultPages = useMemo(
      () => [
        {
          name: "Home",
          path: "/",
          active: isHome,
        },
        {
          name: "Lessons",
          path: "/lessons",
          active: isLessons,
        },
        {
          name: "Questions",
          path: "/questions",
          active: isQuestions,
        },
        {
          name: "Books",
          path: "/books",
          active: isBooks,
        },
      ],
      [isHome, isLessons, isQuestions, isBooks]
    ),
    [pages, setPages] = useState(defaultPages),
    [isLoggedIn, setIsLoggedIn] = useState(false),
    [curPath, setCurPath] = useState(null),
    nav = useNavigate(),
    loc = useLocation(),
    handleOpenLoginMenu = (e) => {
      setLoginAnchor(e.currentTarget);
    },
    handleCloseLoginMenu = () => {
      setLoginAnchor(null);
    },
    handleOpenNavMenu = (event) => {
      setNavAnchor(event.currentTarget);
    },
    handleCloseNavMenu = () => {
      setNavAnchor(null);
    },
    logins = [
      {
        name: "Google",
        path: `/.auth/login/google?post_login_redirect_uri=${curPath}`,
      },
      {
        name: "Microsoft",
        path: `/.auth/login/aad?post_login_redirect_uri=${curPath}`,
      },
    ];

  useEffect(() => {
    setCurPath(loc.pathname);
  }, [loc, setCurPath]);

  useEffect(() => {
    getUser().then((user) => {
      if (user && user.userRoles.indexOf("member") > -1) {
        setPages([
          ...defaultPages,
          {
            name: "Prayers",
            path: "/prayers",
            active: isPrayers,
          },
          {
            name: "Members",
            path: "/members",
            active: isMembers,
          },
        ]);
      }

      setIsLoggedIn(!!user);
    });
  }, [isPrayers, isMembers, setIsLoggedIn, setPages, defaultPages]);

  return (
    <AppBar color="default" position="sticky">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={navAnchor}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(navAnchor)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu();
                    nav(page.path);
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Container sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                color={page.active ? "primary" : "inherit"}
                sx={{ ml: 3, mr: 3 }}
                variant={page.active ? "contained" : "text"}
                onClick={() => {
                  nav(page.path);
                }}
              >
                {page.name}
              </Button>
            ))}
          </Container>
          {!isLoggedIn && (
            <Button color="inherit" onClick={handleOpenLoginMenu}>
              Login
            </Button>
          )}
          {isLoggedIn && (
            <Button
              color="inherit"
              href={`/.auth/logout?post_logout_redirect_uri=${curPath}`}
            >
              Logout
            </Button>
          )}
          <Menu
            anchorEl={loginAnchor}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(loginAnchor)}
            onClose={handleCloseLoginMenu}
          >
            {logins.map((login) => (
              <MenuItem key={login.name} component="a" href={login.path}>
                <Typography textAlign="center">{login.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
