import { useState } from "react";
import {
  createBookClub,
  lookupBookClub,
  updateBookClub,
} from "../../api/BookClubApi";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { SearchTwoTone } from "@mui/icons-material";
import BookClubCard from "./BookClubCard";

function BookClubDialog({ data, onUpdate, onCancel, open }) {
  const [item, setItem] = useState({ ...data }),
    [changes, setChanges] = useState({
      id: data.id,
    }),
    [busy, setBusy] = useState(false),
    [url, setUrl] = useState(data.url),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleLookup = () => {
      setBusy(true);

      lookupBookClub(url)
        .then((result) => {
          setItem({
            ...item,
            ...result,
            ...{ url: url },
          });

          setChanges({
            ...item,
            ...result,
            ...{ url: url },
          });
        })
        .finally(() => setBusy(false));
    },
    handleSubmit = () => {
      const update = (result) => {
        onUpdate && onUpdate(result);
      };

      setBusy(true);

      if (changes.id) {
        updateBookClub(changes)
          .then((result) => update(result))
          .finally(() => setBusy(false));
      } else {
        createBookClub(changes)
          .then((result) => update(result))
          .finally(() => setBusy(false));
      }
    };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
      <DialogTitle>{data.id ? "Edit Book" : "Add Book"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              autoFocus
              fullWidth
              label="Book Link"
              placeholder="Copy or Type Book Link"
              required
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
            <IconButton
              disabled={busy || !url}
              onClick={handleLookup}
              title="Lookup Book"
            >
              <SearchTwoTone />
            </IconButton>
          </Stack>
          {item.url ? (
            <BookClubCard key={item.url} data={item} />
          ) : (
            <>
              <Typography>
                Paste or type a link to the book in the box above and click the
                search icon.
              </Typography>
              <Typography>
                The book information will be loaded here. If you are happy with
                the results, click Save.
              </Typography>
              <Typography>
                Do not use links from Amazon. Search for books on{" "}
                <a
                  href="https://www.christianbook.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Christian Book
                </a>
                . If you do not find it there, try{" "}
                <a
                  href="https://www.lifeway.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Lifeway
                </a>{" "}
                or{" "}
                <a
                  href="https://www.goodreads.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Good Reads
                </a>
                .
              </Typography>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" disabled={busy} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={busy || !changes.url}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BookClubDialog;
