import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createPerson, updatePerson } from "../../api/PersonApi";
import { uploadFile } from "../../api/UploadApi";
import { hasRole } from "../../api/UserApi";

const userTypes = [
  "Leader",
  "Member",
  "BookMember",
  "BookVisitor",
  "BookClub",
  "Visitor",
];

function MemberDialog({ data, onAdd, onUpdate, onCancel }) {
  const [item, setItem] = useState({ ...data }),
    [changes, setChanges] = useState({
      id: data.id,
    }),
    [allowEdit, setAllowEdit] = useState(false),
    [avatarPath, setAvatarPath] = useState(data.avatarPath),
    [avatar, setAvatar] = useState(null),
    [isBusy, setIsBusy] = useState(false),
    handleAvatarChange = (e) => {
      const reader = new FileReader(),
        file = e.target.files[0];

      setAvatar(file);

      reader.onloadend = () => {
        setAvatarPath(reader.result);
      };

      reader.readAsDataURL(file);
    },
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleInputChange = (e) => {
      const key = e.target.name,
        value = e.target.value;

      updateItem(key, value);
    },
    handleNumberChange = (e) => {
      const key = e.target.name,
        value = parseFloat(e.target.value) || 0;

      updateItem(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      setIsBusy(true);

      if (avatar) {
        uploadFile("images", avatar)
          .then((href) => {
            const change = {
              ...changes,
              ...{
                avatarPath: href,
              },
            };

            if (change.id) {
              updatePerson(change)
                .then((record) => {
                  onUpdate && onUpdate(record);
                  setIsBusy(false);
                })
                .catch(() => {
                  setIsBusy(false);
                });
            } else {
              createPerson(change)
                .then((record) => {
                  onAdd && onAdd(record);
                  setIsBusy(false);
                })
                .catch(() => {
                  setIsBusy(false);
                });
            }
          })
          .catch(() => {
            setIsBusy(false);
          });
      } else {
        if (changes.id) {
          updatePerson(changes)
            .then((record) => {
              onUpdate && onUpdate(record);
              setIsBusy(false);
            })
            .catch(() => {
              setIsBusy(false);
            });
        } else {
          createPerson(changes)
            .then((record) => {
              onAdd && onAdd(record);
              setIsBusy(false);
            })
            .catch(() => {
              setIsBusy(false);
            });
        }
      }
    },
    updateItem = (key, value) => {
      let change = {};

      change[key] = value;
      setItem({ ...item, ...change });
      setChanges({ ...changes, ...change });
    };

  useEffect(() => {
    hasRole("leader").then((result) => {
      setAllowEdit(result);
    });
  }, [setAllowEdit]);

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{data.id ? "Edit Member" : "Create Member"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                fullWidth
                label="First Name"
                name="firstName"
                onChange={handleInputChange}
                required
                sx={{ mt: 1 }}
                value={item.firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                onChange={handleInputChange}
                required
                sx={{ mt: 1 }}
                value={item.lastName}
              />
            </Grid>
            {allowEdit && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Type"
                    name="type"
                    onChange={handleInputChange}
                    required
                    select
                    value={item.type}
                  >
                    {userTypes.map((t) => (
                      <MenuItem key={t} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Auth Type"
                    name="authType"
                    onChange={handleInputChange}
                    value={item.authType}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Username"
                    name="username"
                    onChange={handleInputChange}
                    value={item.username}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <Avatar src={avatarPath} sx={{ height: 64, width: 64 }} />
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                inputProps={{ accept: "image/*" }}
                label="Avatar Photo"
                name="avatar"
                onChange={handleAvatarChange}
                sx={{ mt: 1 }}
                type="file"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleInputChange}
                required
                sx={{ mt: 1 }}
                type="email"
                value={item.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                onChange={handleInputChange}
                sx={{ mt: 1 }}
                type="tel"
                value={item.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleInputChange}
                sx={{ mt: 1 }}
                value={item.address}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="City"
                name="city"
                onChange={handleInputChange}
                sx={{ mt: 1 }}
                value={item.city}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="State"
                name="state"
                onChange={handleInputChange}
                sx={{ mt: 1 }}
                value={item.state}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Zip"
                name="zip"
                onChange={handleInputChange}
                sx={{ mt: 1 }}
                value={item.zip}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                inputProps={{
                  min: 1,
                  max: 12,
                  step: 1,
                }}
                label="Birth Month"
                name="birthMonth"
                onChange={handleNumberChange}
                sx={{ mt: 1 }}
                type="number"
                value={item.birthMonth}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                inputProps={{
                  min: 1,
                  max: 31,
                  step: 1,
                }}
                label="Birth Day"
                name="birthDay"
                onChange={handleNumberChange}
                sx={{ mt: 1 }}
                type="number"
                value={item.birthDay}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isBusy} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isBusy} type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default MemberDialog;
