export async function createPerson(model) {
  const response = await fetch("/api/person", {
    method: "POST",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function deletePerson(id) {
  const response = await fetch(`/api/person/${id}`, {
    method: "DELETE",
  });

  if (response.ok) {
    return id;
  }

  throw new Error(await response.text());
}

export async function getPerson({ params }) {
  const response = await fetch(`/api/person/${params.id}`);

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function getPeople() {
  const response = await fetch("/api/person");

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function registerPerson(id) {
  const response = await fetch(`/api/person/${id}/register`, {
    method: "POST",
  });

  if (response.ok) {
    return;
  }

  throw new Error(await response.text());
}

export async function updatePerson(model) {
  const response = await fetch(`/api/person/${model.id}`, {
    method: "PUT",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function updateRegistration(model) {
  const response = await fetch(`/api/person/${model.id}`, {
    method: "PATCH",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}
