export async function answerPrayer(model) {
    const response = await fetch(`/api/prayer/${model.id}/answer`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function createPrayer(model) {
    const response = await fetch("/api/prayer", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function createPrayerComment(model) {
    const response = await fetch(`/api/prayer/${model.prayerId}/comment`, {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function deletePrayer(id) {
    const response = await fetch(`/api/prayer/${id}`, {
        method: "DELETE"
    });

    if (response.ok) {
        return;
    }

    throw new Error(await response.text());
}

export async function deletePrayerComment(prayerId, commentId) {
    const response = await fetch(`/api/prayer/${prayerId}/comment/${commentId}`, {
        method: "DELETE"
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function likePrayer(id) {
    const response = await fetch(`/api/prayer/${id}/like`, {
        method: "PUT"
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function likePrayerComment(prayerId, commentId) {
    const response = await fetch(`/api/prayer/${prayerId}/comment/${commentId}/like`, {
        method: "PUT"
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function getPrayers() {
    const response = await fetch("/api/prayer");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function getPrayer(id) {
    const response = await fetch(`/api/prayer/${id}`);

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function updatePrayer(model) {
    const response = await fetch(`/api/prayer/${model.id}`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function updatePrayerComment(model) {
    const response = await fetch(`/api/prayer/${model.prayerId}/comment/${model.id}`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}