import { Box, createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './cmp/NavBar';
import { deepPurple } from '@mui/material/colors';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(() =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : "light",
          ...(
            prefersDarkMode ?
              {
                primary: {
                  main: deepPurple["A700"]
                }
              } :
              {
                primary: {
                  main: deepPurple[700]
                }
              }
          )
        }
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar />
      <Box
        sx={{ mt: 2, width: 1, height: 1 }}
      >
        <Outlet />
      </Box>
    </ThemeProvider>
  );
}

export default App;
