import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { updateLessonRecording } from "../../api/LessonApi";
import { uploadFile } from "../../api/UploadApi";

function LessonRecordingDialog({ data, onUpdate, onCancel }) {
  const [recording, setRecording] = useState(null),
    [name, setName] = useState(data.name),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleFileChange = (e) => {
      const value = e.target.files[0];

      setRecording(value);
    },
    handleNameChange = (e) => {
      setName(e.target.value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      uploadFile("recordings", recording).then((path) => {
        updateLessonRecording({
          id: data.id,
          bookId: data.bookId,
          name: name,
          recordingPath: path,
        }).then((lesson) => {
          onUpdate && onUpdate(lesson);
        });
      });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Upload Lesson Recording</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              label="Lesson Name"
              name="name"
              onChange={handleNameChange}
              required
              sx={{ mt: 1 }}
              value={name}
            />
            <TextField
              fullWidth
              inputProps={{ accept: "video/mp4" }}
              label="Recording"
              name="recording"
              onChange={handleFileChange}
              required
              type="file"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LessonRecordingDialog;
