import { Masonry } from "@mui/lab";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { getUser } from "../api/UserApi";
import PrayerCard from "../cmp/PrayerCard";
import PrayerDialog from "./prayer/PrayerDialog";
import { getPrayers } from "../api/PrayerApi";

function Prayers() {
    const
        [pending, setPending] = useState(null),
        [answered, setAnswered] = useState(null),
        [allowEdit, setAllowEdit] = useState(false),
        [username, setUsername] = useState(null),
        [addData, setAddData] = useState(false),
        handleAnsweredDelete = (id) => {
            setAnswered(answered.filter(p => {
                return p.id !== id;
            }));
        },
        handleAnsweredUpdate = (item) => {
            setAnswered(answered.map(p => {
                return p.id === item.id ? item : p;
            }));
        },
        handlePendingDelete = (id) => {
            setPending(pending.filter(p => {
                return p.id !== id;
            }));
        },
        handlePendingUpdate = (item) => {
            if (item.dateAnswered) {
                handlePendingDelete(item.id);
                setAnswered([item, ...answered]);
            }
            else {
                setPending(pending.map(p => {
                    return p.id === item.id ? item : p;
                }));
            }
        };

    useEffect(() => {
        getPrayers()
            .then(result => {
                setPending(result.pending);
                setAnswered(result.answered);
            });
    }, [setPending, setAnswered]);

    useEffect(() => {
        getUser()
            .then(user => {
                if (user) {
                    setAllowEdit(user.userRoles.indexOf("leader") > -1)
                    setUsername(user.userDetails);
                }
                else {
                    setAllowEdit(false)
                    setUsername(null);
                }
            });
    }, [setAllowEdit, setUsername]);

    return (
        <>
            <Container
                maxWidth="md"
                sx={{ mt: 2 }}
            >
                <Stack
                    spacing={2}
                >
                    <Toolbar>
                        <Typography
                            component="h2"
                            variant="h5"
                        >
                            Open Prayer Requests
                        </Typography>
                        <Button
                            onClick={() => {
                                setAddData({});
                            }}
                            sx={{ ml: 2 }}
                            variant="contained"
                        >
                            Add a Prayer Request
                        </Button>
                    </Toolbar>
                    {
                        pending ?
                            pending.length ?
                                pending.map(p => (
                                    <PrayerCard
                                        allowEdit={allowEdit}
                                        data={p}
                                        expand={true}
                                        key={p.id}
                                        onDelete={handlePendingDelete}
                                        onUpdate={handlePendingUpdate}
                                        username={username}
                                    />
                                )) :
                                <Typography
                                    paragraph
                                    sx={{ textAlign: "center" }}
                                >
                                    There are no open prayer requests.
                                </Typography> :
                            <CircularProgress />
                    }
                </Stack>
            </Container>
            <Container
                maxWidth="xl"
                sx={{ mt: 2 }}
            >
                <Stack
                    spacing={2}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                    >
                        Answered Prayers
                    </Typography>
                    <Masonry
                        columns={{
                            xs: 1,
                            sm: 2,
                            md: 4
                        }}
                        spacing={2}
                    >
                        {
                            answered ?
                                answered.map(a => (
                                    <PrayerCard
                                        allowEdit={allowEdit}
                                        data={a}
                                        expand={false}
                                        key={a.id}
                                        onDelete={handleAnsweredDelete}
                                        onUpdate={handleAnsweredUpdate}
                                        username={username}
                                    />
                                )) :
                                <CircularProgress />
                        }
                    </Masonry>
                </Stack>
            </Container>
            {
                addData &&
                <PrayerDialog
                    data={addData}
                    onAdd={(prayer) => {
                        setPending([prayer, ...pending]);
                        setAddData(false);
                    }}
                    onCancel={() => {
                        setAddData(false);
                    }}
                />
            }
        </>
    )
}

export default Prayers;