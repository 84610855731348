import { Button, CircularProgress, Grid, List, ListItemButton } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { hasRole } from "../api/UserApi";
import BookDialog from "./lesson/BookDialog";
import { getBooks } from "../api/BookApi";

function Lessons() {
    const
        loc = useLocation(),
        nav = useNavigate(),
        [books, setBooks] = useState(null),
        [showAdd, setShowAdd] = useState(false),
        [allowAdd, setAllowAdd] = useState(false),
        curId = loc.pathname.split("/")[2],
        handleAdd = () => {
            setShowAdd(true);
        },
        handleAdded = (book) => {
            let list = books.filter(item => {
                return item.sortOrder < book.sortOrder;
            });

            list.push(book);

            list = list.concat(books.filter(item => {
                return item.sortOrder > book.sortOrder;
            }));

            handleAddClose();
            setBooks(list);
            nav(`/lessons/${book.id}`);
        },
        handleAddClose = () => {
            setShowAdd(false);
        };

    useEffect(() => {
        getBooks()
            .then(result => {
                setBooks(result);
            });
    }, [setBooks]);

    useEffect(() => {
        hasRole("leader")
            .then(result => {
                setAllowAdd(result);
            });
    }, [setAllowAdd]);

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{ height: 1, ml: 1, mr: 1 }}
            >
                <Grid
                    item
                    sx={{ display: { xs: "none", sm: "inherit" } }}
                    xs={1}
                >
                    <Stack
                        spacing={2}
                    >
                        {
                            allowAdd && <Box>
                                <Button
                                    onClick={handleAdd}
                                    variant="contained"
                                >
                                    Add Book
                                </Button>
                            </Box>
                        }
                        <List>
                            {
                                books ?
                                    books.map(item => (
                                        <ListItemButton
                                            key={item.id}
                                            selected={item.id === curId}
                                            onClick={() => {
                                                nav(item.id);
                                            }}
                                        >
                                            {item.name}
                                        </ListItemButton>
                                    )) :
                                    <CircularProgress
                                    />
                            }
                        </List>
                    </Stack>
                </Grid>
                <Grid
                    item
                    sm={11}
                    xs={12}
                >
                    <Outlet />
                </Grid>
            </Grid>
            {
                showAdd && <BookDialog
                    data={{}}
                    onUpdate={handleAdded}
                    onCancel={handleAddClose}
                />
            }
        </>
    )
}

export default Lessons;