import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";

function Index() {
  return (
    <Container maxWidth="lg" sx={{ mt: 2 }}>
      <Card raised sx={{ maxWidth: "lg" }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1">
                Welcome to our Sunday Group!
              </Typography>
              <Typography paragraph>
                Our prayer is that this class be a community of believers who
                actively pray for, care for, and support each other. Trusting in
                Him who is sovereign, we believe that your presence in this
                class, whether for a season or longer, is purposed and we pray
                that we as a class be used by the Lord to encourage, spiritually
                strengthen, and come along side each other for His glory. We
                desire for our class to be a safe place, one where we can be
                transparent and honest with each other. None of us is perfect
                and we need each other.
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography variant="h5" component="h2">
                Contact Information
              </Typography>
              <Typography paragraph>
                Alan Hedgeman -{" "}
                <a href="mailto:alan@hedgeman.group">alan@hedgeman.group</a>
                <br />
                Tonya Hedgeman -{" "}
                <a href="mailto:tonya@hedgeman.group">tonya@hedgeman.group</a>
              </Typography>
              <Typography variant="h5" component="h2">
                Meeting Times
              </Typography>
              <Typography paragraph>
                We meet each Sunday morning at 11:00 at{" "}
                <a href="https://fbcw.org/" target="_fbcw">
                  First Baptist Church Woodstock
                </a>{" "}
                in room A44/A260.
              </Typography>
              <Typography variant="h5" component="h2">
                Class Description
              </Typography>
              <Typography paragraph>
                Join us to strengthen your Bible study skills! We study entire
                books of the Bible (verse by verse), with a focus on learning
                about God, understanding His word, and learning how to apply His
                word to our everyday lives. We encourage independent
                reading/study during the week and then on Sunday, we gather and
                discuss the text in a facilitated, Q&A style.
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <img
                alt="Hedgeman Small Group"
                src="https://hgroup.blob.core.windows.net/images/DSCN0130.JPG"
                style={{ maxWidth: "100%" }}
              />
              <Typography variant="body2" component="p">
                The fear of the Lord is the beginning of wisdom, <br />
                And the knowledge of the Holy One is understanding. (Proverbs
                9:10)
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}>
              <img
                alt="Hedgeman Small Group"
                src="https://hgroup.blob.core.windows.net/images/DSCN0127.JPG"
                style={{ maxWidth: "100%" }}
              />
              <Typography variant="body2" component="p">
                Let us hold fast the confession of our hope without wavering,
                for He who promised is faithful; and let us consider how to
                stimulate one another to love and good deeds, not forsaking our
                own assembling together, as is the habit of some, but
                encouraging one another; and all the more as you see the day
                drawing near. (Hebrews 10:23-25)
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Typography variant="h5" component="h2">
                Class Process
              </Typography>
              <Typography paragraph>
                <strong>Homework: </strong>
                Alan will email the homework assignment on Monday. Please read
                and/or study the scriptures. We know everyone’s schedule is
                different so no pressure. Try to at least read the text so that
                on Sunday it will be familiar. We encourage the{" "}
                <a
                  href="https://biblestudy.tips/inductive-bible-study/"
                  target="_ibs"
                >
                  inductive study
                </a>{" "}
                method.
              </Typography>
              <Typography paragraph>
                <strong>Notes: </strong>
                Alan will send notes via email on Friday or Saturday (as his
                work schedule permits). If you have a printer, please print a
                copy for class. We will have a few copies in class. The notes
                will be discussed and questions answered on Sunday.
              </Typography>
              <Typography paragraph>
                <strong>Zoom: </strong>
                Although we would much rather have you present in class, we have
                an{" "}
                <a href="https://us04web.zoom.us/j/443281056" target="_zoom">
                  online option
                </a>{" "}
                for those who cannot make it to church. Sessions are also
                recorded if you need to watch it later.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                Course of Study
              </Typography>
              <Typography paragraph>
                Currently we are on a journey through the writings of the
                Apostle John. Here are our next steps:
                <ul>
                  <li>John</li>
                  <li>1 John</li>
                  <li>2 John</li>
                  <li>3 John</li>
                  <li>Revelation</li>
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Index;
