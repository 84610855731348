import { ArrowBackTwoTone } from "@mui/icons-material";
import { Button, CircularProgress, Toolbar } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../api/UserApi";
import { getBookClub } from "../../api/BookClubApi";
import BookClubCard from "./BookClubCard";

function Book() {
  const params = useParams(),
    [data, setData] = useState(null),
    [allowVote, setAllowVote] = useState(false),
    [allowAdmin, setAllowAdmin] = useState(false),
    [username, setUsername] = useState(null),
    nav = useNavigate(),
    handleBack = () => {
      nav("/books");
    };

  useEffect(() => {
    getBookClub(params.id).then((result) => {
      setData(result);
    });
  }, [params, setData]);

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setAllowVote(user.userRoles.indexOf("bookclub") > -1);
        setAllowAdmin(user.userRoles.indexOf("leader") > -1);
        setUsername(user.userDetails);
      } else {
        setAllowVote(false);
        setAllowAdmin(false);
        setUsername(null);
      }
    });
  }, [setAllowVote, setAllowAdmin, setUsername]);

  return (
    <Container maxWidth="sm">
      <Stack spacing={2}>
        <Toolbar>
          <Button
            onClick={handleBack}
            startIcon={<ArrowBackTwoTone />}
            variant="text"
          >
            Back to Books
          </Button>
        </Toolbar>
        {data ? (
          <BookClubCard
            allowAdmin={allowAdmin}
            allowEdit={allowAdmin || username === data.username}
            allowVote={allowVote}
            data={data}
            expand
            onDelete={handleBack}
            onUpdate={(update) => setData({ ...data, ...update })}
            username={username}
          />
        ) : (
          <CircularProgress />
        )}
      </Stack>
    </Container>
  );
}

export default Book;
