import { ArrowBack } from "@mui/icons-material";
import { Button, CircularProgress, Toolbar } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../api/UserApi";
import PrayerCard from "../../cmp/PrayerCard";
import { getPrayer } from "../../api/PrayerApi";

function Prayer() {
    const
        params = useParams(),
        [data, setData] = useState(null),
        [allowEdit, setAllowEdit] = useState(false),
        [username, setUsername] = useState(null),
        nav = useNavigate(),
        handleBack = () => {
            nav("/prayers");
        };

    useEffect(() => {
        getPrayer(params.id)
            .then(result => {
                setData(result);
            });
    }, [params, setData]);

    useEffect(() => {
        getUser()
            .then(user => {
                if (user) {
                    setAllowEdit(user.userRoles.indexOf("leader") > -1)
                    setUsername(user.userDetails);
                }
                else {
                    setAllowEdit(false);
                    setUsername(null);
                }
            });
    }, [setAllowEdit, setUsername]);

    return (
        <Container
            maxWidth="md"
        >
            <Stack
                spacing={2}
            >
                <Toolbar>
                    <Button
                        onClick={handleBack}
                        startIcon={<ArrowBack />}
                        variant="text"
                    >
                        Back to Prayers
                    </Button>
                </Toolbar>
                {
                    data ?
                        <PrayerCard
                            allowEdit={allowEdit}
                            data={data}
                            expand={true}
                            onDelete={handleBack}
                            username={username}
                        /> :
                        <CircularProgress />
                }
            </Stack>
        </Container>
    )
}

export default Prayer;