import {
  AdminPanelSettingsTwoTone,
  ContactMailTwoTone,
  Diversity3TwoTone,
  EditTwoTone,
  MenuBookTwoTone,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { deletePerson, getPeople, registerPerson } from "../api/PersonApi";
import { getUser } from "../api/UserApi";
import ConfirmDelete from "../cmp/ConfirmDelete";
import MemberDialog from "./member/MemberDialog";

const registerTypes = [
    "Leader",
    "Member",
    "BookMember",
    "BookVisitor",
    "BookClub",
  ],
  memberTypes = ["Member", "BookMember"],
  bookTypes = ["BookMember", "BookVisitor", "BookClub"],
  mailTypes = ["Visitor", "BookVisitor"],
  filterOptions = [
    {
      id: "Leader",
      label: "Group Leaders",
    },
    {
      id: "Leader|Member|BookMember",
      label: "Group Members",
    },
    {
      id: "Leader|BookMember|BookVisitor|BookClub",
      label: "Book Club Members",
    },
    {
      id: "Leader|Visitor|BookVisitor",
      label: "Group Mailing List",
    },
  ];

function Members() {
  const [data, setData] = useState(null),
    [people, setPeople] = useState(null),
    [allowDetail, setAllowDetail] = useState(false),
    [allowEdit, setAllowEdit] = useState(false),
    [username, setUsername] = useState(null),
    [editorData, setEditorData] = useState(false),
    [deleteData, setDeleteData] = useState(false),
    [to, setTo] = useState(""),
    [bcc, setBcc] = useState(""),
    [filters, setFilters] = useState([]),
    handleAdd = (record) => {
      setPeople(sort([...people, record]));
      handleCloseDialog();
    },
    handleCloseDialog = () => {
      setEditorData(false);
    },
    handleUpdate = (record) => {
      setPeople(sort(replace(people, record)));
      handleCloseDialog();
    },
    handleRegister = (id) => {
      registerPerson(id).then(() => {});
    },
    filter = (list, id) => {
      return list.filter((item) => {
        return item.id !== id;
      });
    },
    sort = (list) => {
      return list.sort((a, b) => {
        const result =
          a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0;

        if (result) {
          return result;
        }

        return a.firstName < b.firstName
          ? -1
          : a.firstName > b.firstName
          ? 1
          : 0;
      });
    },
    replace = (list, update) => {
      return list.map((item) => {
        return item.id === update.id ? update : item;
      });
    };

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setAllowDetail(user.userRoles.indexOf("member") > -1);
        setAllowEdit(user.userRoles.indexOf("leader") > -1);
        setUsername(user.userDetails);
      } else {
        setAllowDetail(false);
        setAllowEdit(false);
        setUsername(null);
      }
    });
  }, [setAllowDetail, setAllowEdit, setUsername]);

  useEffect(() => {
    getPeople().then((result) => {
      setData(result);
    });
  }, [setData]);

  useEffect(() => {
    if (data) {
      if (filters.length) {
        setPeople(data.filter((p) => filters.indexOf(p.type) > -1));
      } else {
        setPeople([...data]);
      }
    }
  }, [data, filters, setPeople]);

  useEffect(() => {
    if (people) {
      setTo(
        people
          .filter((p) => p.type === "Leader")
          .map((item) => `${item.firstName} ${item.lastName} <${item.email}>`)
          .join("; ")
      );
      setBcc(
        people
          .filter((p) => p.type !== "Leader")
          .map((item) => `${item.firstName} ${item.lastName} <${item.email}>`)
          .join("; ")
      );
    }
  }, [people, setTo, setBcc]);

  return (
    <>
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <Toolbar>
            {allowEdit && (
              <Button
                onClick={() => {
                  setEditorData({ state: "GA" });
                }}
                variant="contained"
              >
                Add Person
              </Button>
            )}
            {allowDetail && (
              <Button
                href={`mailto:${to}?bcc=${bcc}`}
                sx={{ ml: 2 }}
                color="inherit"
              >
                Send Email
              </Button>
            )}
            <Autocomplete
              multiple
              options={filterOptions}
              sx={{ ml: 2, flexGrow: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Filter"
                />
              )}
              onChange={(_, value) => {
                let list = [];

                value.forEach((v) => (list = list.concat(v.id.split("|"))));
                setFilters(list);
              }}
            />
          </Toolbar>
          <Grid container spacing={2}>
            {people ? (
              people.map((item) => (
                <Grid item key={item.id} xs={12} sm={6} md={3}>
                  <Card raised>
                    <CardHeader
                      action={
                        (allowEdit ||
                          (username && item.username === username)) && (
                          <IconButton
                            onClick={() => {
                              setEditorData(item);
                            }}
                          >
                            <EditTwoTone />
                          </IconButton>
                        )
                      }
                      avatar={
                        <Avatar
                          src={item.avatarPath}
                          sx={{ height: 64, width: 64 }}
                        />
                      }
                      title={`${item.firstName} ${item.lastName}`}
                    />
                    {allowDetail && (
                      <CardContent>
                        <Typography color="text.secondary" variant="body2">
                          Contact
                        </Typography>
                        <Typography variant="body">
                          <a href={`mailto:${item.email}`}>{item.email}</a>
                          <br />
                          <a href={`tel:${item.phone}`}>
                            {item.phone || "(unknown)"}
                          </a>
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ mt: 1 }}
                          variant="body2"
                        >
                          Address
                        </Typography>
                        <Typography variant="body">
                          {item.address}
                          <br />
                          {item.address
                            ? `${item.city}, ${item.state} ${item.zip}`
                            : "(unknown)"}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          sx={{ mt: 1 }}
                          variant="body2"
                        >
                          Birthday
                        </Typography>
                        <Typography variant="body">
                          {item.birthMonth
                            ? `${item.birthMonth}/${item.birthDay}`
                            : "(unknown)"}
                        </Typography>
                      </CardContent>
                    )}
                    <CardActions>
                      <Button
                        color="inherit"
                        sx={{ display: allowEdit ? "inherit" : "none" }}
                        onClick={() => {
                          setDeleteData({
                            itemName: `${item.firstName} ${item.lastName}`,
                            handleConfirm: () => {
                              deletePerson(item.id).then(() => {
                                setPeople(filter(people, item.id));
                                setDeleteData(false);
                              });
                            },
                          });
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        color="inherit"
                        sx={{
                          display:
                            allowEdit &&
                            !item.username &&
                            registerTypes.indexOf(item.type) > -1
                              ? "inherit"
                              : "none",
                        }}
                        onClick={() => {
                          handleRegister(item.id);
                        }}
                      >
                        Register
                      </Button>
                      <div style={{ flexGrow: 1 }} />
                      {item.type === "Leader" && (
                        <span title="Group Leader">
                          <AdminPanelSettingsTwoTone />
                        </span>
                      )}
                      {memberTypes.indexOf(item.type) > -1 && (
                        <span title="Group Member">
                          <Diversity3TwoTone />
                        </span>
                      )}
                      {bookTypes.indexOf(item.type) > -1 && (
                        <span title="Book Club Member">
                          <MenuBookTwoTone />
                        </span>
                      )}
                      {mailTypes.indexOf(item.type) > -1 && (
                        <span title="Group Mailing List">
                          <ContactMailTwoTone />
                        </span>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Stack>
      </Container>
      {editorData && (
        <MemberDialog
          data={editorData}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onCancel={handleCloseDialog}
        />
      )}
      {deleteData && (
        <ConfirmDelete
          itemName={deleteData.itemName}
          onConfirm={deleteData.handleConfirm}
          onCancel={() => {
            setDeleteData(false);
          }}
        />
      )}
    </>
  );
}

export default Members;
