import { Alert, Button, Card, CardActions, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { updateRegistration } from "../../api/PersonApi";
import { LoginTwoTone, SaveTwoTone } from "@mui/icons-material";

function Register() {
    const data = useLoaderData(),
        [item, setItem] = useState({ ...data }),
        [changes, setChanges] = useState({
            id: data.id
        }),
        [allowSave, setAllowSave] = useState(!data.authType || !data.username),
        [allowLogin, setAllowLogin] = useState(!!data.authType && !!data.username),
        [status, setStatus] = useState(data.authType && data.username ? "You have already registered. Please use your existing account to login." : null),
        handleInputChange = (e) => {
            const key = e.target.name,
                value = e.target.value;

            updateItem(key, value);
        },
        handleSubmit = (e) => {
            e.preventDefault();

            setAllowSave(false);

            updateRegistration(changes)
                .then(record => {
                    setStatus("Thank your for registering. You should now be able to login.");
                    setItem(record);
                    setAllowLogin(true);
                });
        },
        updateItem = (key, value) => {
            let change = {};

            change[key] = value;
            setItem({ ...item, ...change });
            setChanges({ ...changes, ...change });
        };

    return (
        <Container
            maxWidth="sm"
            sx={{ mt: 2 }}
        >
            <Card
                raised>
                <CardHeader
                    title="Register for a Hedgeman Group Account"
                />
                <form
                    onSubmit={handleSubmit}
                >
                    <CardContent>
                        <Typography
                            paragraph
                        >
                            {`Hello ${item.firstName}. Thank you for setting up your member account. You can choose either of the 3rd-party serivces to login to the Hedgeman Group website. This is for your safety. Your password will not be shared with this website.`}
                        </Typography>
                        <FormControl
                            required
                        >
                            <FormLabel>Please choose your login provider</FormLabel>
                            <RadioGroup
                                name="authType"
                                value={item.authType}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel
                                    control={<Radio />}
                                    label={
                                        <>
                                            <Typography
                                                component="span"
                                                sx={{ display: "inline" }}
                                            >
                                                Google
                                            </Typography>
                                            <Typography
                                                component="span"
                                                sx={{ display: "inline", ml: 1 }}
                                                variant="body2"
                                            >
                                                (Need an account? Click <a href="https://www.google.com/account/about/?hl=en-US" target="_google">here</a>.)
                                            </Typography>
                                        </>
                                    }
                                    value="google"
                                />
                                <FormControlLabel
                                    control={<Radio />}
                                    label={
                                        <>
                                            <Typography
                                                component="span"
                                                sx={{ display: "inline" }}
                                            >
                                                Microsoft
                                            </Typography>
                                            <Typography
                                                component="span"
                                                sx={{ display: "inline", ml: 1 }}
                                                variant="body2"
                                            >
                                                (Need an account? Click <a href="https://account.microsoft.com/" target="_aad">here</a>.)
                                            </Typography>
                                        </>
                                    }
                                    value="aad"
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Enter the email you use with the selected provider"
                            name="username"
                            onChange={handleInputChange}
                            required
                            sx={{ mt: 2 }}
                            value={item.username}
                        />
                        {
                            status && <Alert
                                severity="success"
                                sx={{ mt: 2 }}
                            >
                                {status}
                            </Alert>
                        }
                    </CardContent>
                    {
                        allowSave &&
                        <CardActions>
                            <Button
                                startIcon={<SaveTwoTone />}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                        </CardActions>
                    }
                    {
                        allowLogin &&
                        <CardActions>
                            <Button
                                href={`/.auth/login/${item.authType}?post_login_redirect_uri=/`}
                                startIcon={<LoginTwoTone />}
                                variant="contained"
                            >
                                Login
                            </Button>
                        </CardActions>
                    }
                </form>
            </Card>
        </Container>
    );
}

export default Register;