export async function answerQuestion(model) {
  const response = await fetch(`/api/question/${model.id}/answer`, {
    method: "PUT",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function createQuestion(model) {
  const response = await fetch("/api/question", {
    method: "POST",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function deleteQuestion(id) {
  const response = await fetch(`/api/question/${id}`, {
    method: "DELETE",
  });

  if (response.ok) {
    return id;
  }

  throw new Error(await response.text());
}

export async function getQuestions() {
  const response = await fetch("/api/question");

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function selectQuestion(model) {
  const response = await fetch(`/api/question/${model.id}/select`, {
    method: "PUT",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function updateQuestion(model) {
  const response = await fetch(`/api/question/${model.id}`, {
    method: "PUT",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function voteUpQuestion(id) {
  const response = await fetch(`/api/question/${id}/voteup`, {
    method: "PUT",
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}
