import { Comment, Delete, Done, Edit, Favorite, ThumbUp } from "@mui/icons-material";
import { Avatar, Badge, Card, CardActions, CardContent, CardHeader, Collapse, IconButton, styled, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrayerDialog from "../view/prayer/PrayerDialog";
import { deletePrayer, deletePrayerComment, likePrayer, likePrayerComment } from "../api/PrayerApi";
import ConfirmDelete from "../cmp/ConfirmDelete";
import PrayerAnswerDialog from "../view/prayer/PrayerAnswerDialog";
import { Clone } from "../api/Utils";
import PrayerCommentDialog from "../view/prayer/PrayerCommentDialog";
import { Link } from "react-router-dom";

function PrayerCard({ data, allowEdit, username, expand, onUpdate, onDelete }) {
    const [item, setItem] = useState(JSON.parse(JSON.stringify(data))),
        [prayerEdit, setPrayerEdit] = useState(false),
        [prayerDelete, setPrayerDelete] = useState(false),
        [prayerAnswer, setPrayerAnswer] = useState(false),
        [prayerComment, setPrayerComment] = useState(false),
        [prayerCommentDelete, setPrayerCommentDelete] = useState(false),
        [expanded, setExpanded] = useState(!!expand),
        [isLikePrayerBusy, setIsLikePrayerBusy] = useState(false),
        [isLikeCommentBusy, setIsLikeCommentBusy] = useState(false),
        getDate = (dateString) => {
            const utcDate = new Date(dateString);

            return utcDate.toLocaleString();
        },
        handleLikeComment = c => {
            if (c.username !== username && c.likes.indexOf(username) === -1) {
                setIsLikeCommentBusy(true);

                likePrayerComment(item.id, c.id)
                    .then(update => {
                        let clone = Clone(item),
                            comment = clone.comments.find(cc => {
                                return cc.id === c.id;
                            });

                        if (comment) {
                            comment.likes = update.likes;

                            clone.comments = clone.comments.map(cc => {
                                return cc.id === comment.id ? comment : cc;
                            });

                            setItem(clone);
                            onUpdate && onUpdate(clone);
                        }

                        setIsLikeCommentBusy(false);
                    })
                    .catch(() => {
                        setIsLikeCommentBusy(false);
                    });
            }
        },
        handleLikePrayer = p => {
            if (p.username !== username && p.likes.indexOf(username) === -1) {
                setIsLikePrayerBusy(true);

                likePrayer(p.id)
                    .then(update => {
                        let clone = Clone(p);

                        clone.likes = update.likes;
                        setItem(clone);
                        onUpdate && onUpdate(clone);

                        setIsLikePrayerBusy(false);
                    })
                    .catch(() => {
                        setIsLikePrayerBusy(false);
                    });
            }
        };

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest
        })
    }));

    return (
        <>
            <Card
                raised
                sx={{ pl: 1, pr: 1 }}
            >
                <CardHeader
                    action={
                        (allowEdit || (username && item.username === username)) &&
                        <Stack
                            direction="row"
                            spacing={1}
                        >
                            <IconButton
                                onClick={() => {
                                    setPrayerEdit(item);
                                }}
                                title="Edit Prayer"
                            >
                                <Edit />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setPrayerDelete(true);
                                }}
                                title="Delete Prayer"
                            >
                                <Delete />
                            </IconButton>
                            {
                                !item.dateAnswered &&
                                <IconButton
                                    onClick={() => {
                                        setPrayerAnswer({
                                            id: item.id
                                        });
                                    }}
                                    title="Mark Prayer as Answered"
                                >
                                    <Done />
                                </IconButton>
                            }
                        </Stack>
                    }
                    avatar={
                        <Avatar
                            src={item.avatarPath}
                            sx={{ height: 64, width: 64 }}
                        />
                    }
                    subheader={item.dateAnswered ?
                        getDate(item.dateAnswered) :
                        getDate(item.dateCreated)}
                    title={<Link
                        title="Full Screen"
                        to={`/prayers/${item.id}`}
                    >
                        {item.author}
                    </Link>}
                />
                <CardContent>
                    {
                        item.description.split("\n").map(p => (
                            <Typography>
                                {p}
                            </Typography>
                        ))
                    }
                </CardContent>
                <CardActions
                    disableSpacing
                >
                    <Badge
                        badgeContent={item.likes.length}
                        color="primary"
                    >
                        <IconButton
                            disabled={isLikePrayerBusy}
                            onClick={() => {
                                handleLikePrayer(item);
                            }}
                            title="I am Praying"
                        >
                            <Favorite />
                        </IconButton>
                    </Badge>
                    <Badge
                        badgeContent={item.comments.length}
                        color="primary"
                        showZero
                    >
                        <IconButton
                            onClick={() => {
                                setPrayerComment({
                                    prayerId: item.id
                                });
                            }}
                            sx={{ ml: 1 }}
                            title="Add Comment"
                        >
                            <Comment />
                        </IconButton>
                    </Badge>
                    <ExpandMore
                        expand={expanded}
                        onClick={() => {
                            setExpanded(!expanded)
                        }}
                        title={expanded ? "Collapse Comments" : "Expand Comments"}
                    >
                        <ExpandMoreIcon />
                    </ExpandMore>
                </CardActions>
                <Collapse
                    in={expanded}
                >
                    <Stack
                        spacing={2}
                        sx={{ ml: 2, mb: 1 }}
                    >
                        {
                            item.comments.map(c => (
                                <Card
                                    key={c.id}
                                    raised
                                >
                                    <CardHeader
                                        action={
                                            (allowEdit || (username && c.username === username)) &&
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                <IconButton
                                                    onClick={() => {
                                                        setPrayerComment({
                                                            id: c.id,
                                                            description: c.description,
                                                            prayerId: item.id
                                                        });
                                                    }}
                                                    title="Edit Comment"
                                                >
                                                    <Edit />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        setPrayerCommentDelete({
                                                            commentId: c.id,
                                                            prayerId: item.id
                                                        });
                                                    }}
                                                    title="Delete Comment"
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </Stack>
                                        }
                                        avatar={
                                            <Avatar
                                                src={c.avatarPath}
                                            />
                                        }
                                        subheader={getDate(c.dateCreated)}
                                        title={c.author}
                                    />
                                    <CardContent>
                                        {
                                            c.description.split("\n").map(p => (
                                                <Typography>
                                                    {p}
                                                </Typography>
                                            ))
                                        }
                                    </CardContent>
                                    <CardActions>
                                        <Badge
                                            badgeContent={c.likes.length}
                                            color="primary"
                                        >
                                            <IconButton
                                                disabled={isLikeCommentBusy}
                                                onClick={() => {
                                                    handleLikeComment(c);
                                                }}
                                                title="Like Comment"
                                            >
                                                <ThumbUp />
                                            </IconButton>
                                        </Badge>
                                    </CardActions>
                                </Card>
                            ))
                        }
                    </Stack>
                </Collapse>
            </Card>
            {
                prayerEdit &&
                <PrayerDialog
                    data={prayerEdit}
                    onCancel={() => {
                        setPrayerEdit(false);
                    }}
                    onUpdate={(update) => {
                        setItem({ ...item, ...update });
                        setPrayerEdit(false);
                        onUpdate && onUpdate(item);
                    }}
                />
            }
            {
                prayerDelete &&
                <ConfirmDelete
                    itemName="this prayer request"
                    onCancel={() => {
                        setPrayerDelete(false);
                    }}
                    onConfirm={() => {
                        deletePrayer(item.id)
                            .then(() => {
                                onDelete && onDelete(item.id);
                                setPrayerDelete(false);
                            });
                    }}
                />
            }
            {
                prayerAnswer &&
                <PrayerAnswerDialog
                    data={prayerAnswer}
                    onCancel={() => {
                        setPrayerAnswer(false);
                    }}
                    onUpdate={(update) => {
                        setItem(update);
                        setPrayerAnswer(false);
                        onUpdate && onUpdate(update);
                    }}
                />
            }
            {
                prayerComment &&
                <PrayerCommentDialog
                    data={prayerComment}
                    onAdd={(c => {
                        let clone = Clone(item);

                        clone.comments.push(c);
                        setItem(clone);
                        onUpdate && onUpdate(clone);
                        setPrayerComment(false);
                    })}
                    onCancel={() => {
                        setPrayerComment(false);
                    }}
                    onUpdate={c => {
                        let clone = Clone(item),
                            comment = clone.comments.find(cc => {
                                return cc.id === c.id;
                            });

                        if (comment) {
                            let commentClone = Clone(comment);

                            commentClone.description = c.description;

                            clone.comments = clone.comments.map(cc => {
                                return commentClone.id === cc.id ?
                                    commentClone :
                                    cc;
                            });

                            setItem(clone);
                            onUpdate && onUpdate(clone);
                            setPrayerComment(false);
                        }
                    }}
                />
            }
            {
                prayerCommentDelete &&
                <ConfirmDelete
                    itemName="this prayer comment"
                    onCancel={() => {
                        setPrayerCommentDelete(false);
                    }}
                    onConfirm={() => {
                        deletePrayerComment(prayerCommentDelete.prayerId, prayerCommentDelete.commentId)
                            .then(() => {
                                let clone = Clone(item);

                                clone.comments = clone.comments.filter(c => {
                                    return c.id !== prayerCommentDelete.commentId;
                                });

                                setItem(clone);
                                onUpdate && onUpdate(clone);
                                setPrayerCommentDelete(false);
                            });
                    }}
                />
            }
        </>
    );
}

export default PrayerCard;