import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function ConfirmDelete({ itemName, onConfirm, onCancel }) {
  const handleClose = (e, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleConfirm = () => {
      onConfirm && onConfirm();
    };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={true}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete ${
            itemName ? itemName : "the selected item"
          }?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button autoFocus onClick={handleConfirm} variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDelete;
