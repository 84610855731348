import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { createLesson } from "../../api/LessonApi";

function LessonDialog({ data, onUpdate, onCancel }) {
  const [item, setItem] = useState({ ...data }),
    [changes, setChanges] = useState({
      id: data.id,
      bookId: data.bookId,
    }),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleInputChange = (e) => {
      const key = e.target.name,
        value = e.target.value;

      updateItem(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      createLesson(changes).then((lesson) => {
        onUpdate && onUpdate(lesson);
      });
    },
    updateItem = (key, value) => {
      let change = {};

      change[key] = value;
      setItem({ ...item, ...change });
      setChanges({ ...changes, ...change });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Create a Lesson</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              name="name"
              onChange={handleInputChange}
              required
              sx={{ mt: 1 }}
              value={item.name}
            />
            <TextField
              fullWidth
              label="Lesson date"
              name="lessonDate"
              onChange={handleInputChange}
              required
              type="date"
              value={item.lessonDate}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LessonDialog;
