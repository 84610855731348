import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { answerQuestion } from "../../api/QuestionApi";
import { uploadFile } from "../../api/UploadApi";

function AnswerDialog({ data, onUpdate, onCancel }) {
  const [item, setItem] = useState({ ...data }),
    [changes, setChanges] = useState({
      id: data.id,
    }),
    [files, setFiles] = useState({
      notes: null,
      recording: null,
    }),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleFileChange = (e) => {
      const key = e.target.name,
        value = e.target.files[0];

      let change = {};

      change[key] = value;
      setFiles({ ...files, ...change });
    },
    handleInputChange = (e) => {
      const key = e.target.name,
        value = e.target.value;

      updateItem(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      uploadFile("notes", files.notes).then((notesPath) => {
        if (files.recording) {
          uploadFile("recordings", files.recording).then((recordingPath) => {
            saveChanges({
              ...changes,
              ...{
                notesPath: notesPath,
                recordingPath: recordingPath,
              },
            });
          });
        } else {
          saveChanges({
            ...changes,
            ...{
              notesPath: notesPath,
            },
          });
        }
      });
    },
    updateItem = (key, value) => {
      let change = {};

      change[key] = value;
      setItem({ ...item, ...change });
      setChanges({ ...changes, ...change });
    },
    saveChanges = (update) => {
      answerQuestion(update).then((question) => {
        onUpdate && onUpdate(question);
      });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Answer Question</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              label="Subject"
              name="subject"
              onChange={handleInputChange}
              required
              sx={{ mt: 1 }}
              value={item.subject}
            />
            <TextField
              fullWidth
              label="Answer Date"
              name="dateAnswered"
              onChange={handleInputChange}
              required
              type="date"
              value={item.dateAnswered}
            />
            <TextField
              fullWidth
              inputProps={{ accept: "application/pdf" }}
              label="Notes"
              name="notes"
              onChange={handleFileChange}
              required
              type="file"
            />
            <TextField
              fullWidth
              inputProps={{ accept: "video/mp4" }}
              label="Recording"
              name="recording"
              onChange={handleFileChange}
              type="file"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AnswerDialog;
