import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Masonry } from "@mui/lab";
import { useEffect, useState } from "react";
import BookClubDialog from "./bookclub/BookClubDialog";
import { getBookClubs } from "../api/BookClubApi";
import BookClubCard from "./bookclub/BookClubCard";
import { getUser } from "../api/UserApi";

function BookClub() {
  const [dialog, setDialog] = useState(null),
    [books, setBooks] = useState(null),
    [current, setCurrent] = useState([]),
    [list, setList] = useState([]),
    [completed, setCompleted] = useState([]),
    [allowVote, setAllowVote] = useState(false),
    [allowAdmin, setAllowAdmin] = useState(false),
    [username, setUsername] = useState(null),
    [sortByVotes, setSortByVotes] = useState(true),
    handleDelete = (id) => {
      setBooks(books.filter((b) => b.id !== id));
    },
    handleUpdate = (book) => {
      setBooks(books.map((b) => (b.id === book.id ? { ...b, ...book } : b)));
    };

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setAllowVote(user.userRoles.indexOf("bookclub") > -1);
        setAllowAdmin(user.userRoles.indexOf("leader") > -1);
        setUsername(user.userDetails);
      } else {
        setAllowVote(false);
        setAllowAdmin(false);
        setUsername(null);
      }
    });
  }, [setAllowVote, setAllowAdmin, setUsername]);

  useEffect(() => {
    getBookClubs().then((result) => setBooks(result));
  }, [setBooks]);

  useEffect(() => {
    if (books) {
      setCurrent(
        books
          .filter((b) => !b.isComplete && b.meetings.length)
          .sort((a, b) => {
            const aDate = new Date(a.meetings[a.meetings.length - 1].date),
              bDate = new Date(b.meetings[b.meetings.length - 1].date);

            return aDate - bDate;
          })
      );

      setList(
        books
          .filter((b) => !b.isComplete && !b.meetings.length)
          .sort((a, b) => {
            const aVotes = a.likes.length,
              bVotes = b.likes.length,
              aTitle = a.title.toUpperCase(),
              bTitle = b.title.toUpperCase();

            if (sortByVotes) {
              if (aVotes === bVotes) {
                const aDate = new Date(a.dateCreated),
                  bDate = new Date(b.dateCreated);

                return aDate - bDate;
              } else {
                return bVotes - aVotes;
              }
            } else {
              return aTitle < bTitle ? -1 : 1;
            }
          })
      );

      setCompleted(
        books
          .filter((b) => b.isComplete)
          .sort((a, b) => {
            const aDate = new Date(a.dateCompleted),
              bDate = new Date(b.dateCompleted);

            return bDate - aDate;
          })
      );
    }
  }, [books, setCurrent, setList, setCompleted, sortByVotes]);

  return (
    <Container maxWidth="xl" sx={{ mt: 2 }}>
      {books ? (
        <Stack spacing={2}>
          <Container maxWidth={current.length > 1 ? "md" : "xs"}>
            <Grid container spacing={2}>
              {current.map((b) => (
                <Grid item key={b.id} xs={12 / current.length}>
                  <BookClubCard
                    allowAdmin={allowAdmin}
                    allowEdit={allowAdmin || b.username === username}
                    allowLink
                    allowVote={allowVote}
                    data={b}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    username={username}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
          <Toolbar>
            <Typography component="h2" variant="h5">
              Book List
            </Typography>
            {allowVote && (
              <Button
                onClick={() => {
                  setDialog({});
                }}
                sx={{ ml: 2 }}
                variant="contained"
              >
                Add a Book
              </Button>
            )}
            <FormControl sx={{ ml: 2 }}>
              <RadioGroup
                row
                onChange={(e) => setSortByVotes(e.target.value === "true")}
                value={sortByVotes}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Sort by Votes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Sort by Title"
                />
              </RadioGroup>
            </FormControl>
          </Toolbar>
          <Masonry
            columns={{
              xs: 1,
              sm: 2,
              md: 4,
            }}
            spacing={2}
          >
            {list.map((b) => (
              <BookClubCard
                key={b.id}
                allowAdmin={allowAdmin}
                allowEdit={allowAdmin || b.username === username}
                allowLink
                allowVote={allowVote}
                data={b}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                username={username}
              />
            ))}
          </Masonry>
          <Typography component="h2" variant="h5">
            Completed Books
          </Typography>
          <Masonry
            columns={{
              xs: 1,
              sm: 2,
              md: 4,
            }}
            spacing={2}
          >
            {completed.map((b) => (
              <BookClubCard
                key={b.id}
                allowAdmin={allowAdmin}
                allowEdit={allowAdmin || b.username === username}
                allowLink
                allowVote={allowVote}
                data={b}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
                username={username}
              />
            ))}
          </Masonry>
        </Stack>
      ) : (
        <CircularProgress />
      )}
      {dialog && (
        <BookClubDialog
          data={dialog}
          open={!!dialog}
          onCancel={() => setDialog(null)}
          onUpdate={(result) => {
            setBooks([...books, result]);
            setDialog(null);
          }}
        />
      )}
    </Container>
  );
}

export default BookClub;
