import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getPerson } from "./api/PersonApi";
import App from "./App";
import Error from "./cmp/Error";
import Index from "./view/Index";
import LessonIndex from "./view/lesson/LessonIndex";
import LessonList from "./view/lesson/LessonList";
import Lessons from "./view/Lessons";
import Login from "./view/Login";
import Register from "./view/member/Register";
import Members from "./view/Members";
import Prayer from "./view/prayer/Prayer";
import Prayers from "./view/Prayers";
import Questions from "./view/Questions";
import BookClub from "./view/BookClub";
import Book from "./view/bookclub/Book";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: "lessons",
        element: <Lessons />,
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: <LessonIndex />,
          },
          {
            path: ":bookId",
            element: <LessonList />,
          },
        ],
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "questions",
        element: <Questions />,
      },
      {
        path: "books",
        element: <BookClub />,
      },
      {
        path: "books/:id",
        element: <Book />,
      },
      {
        path: "prayers",
        element: <Prayers />,
      },
      {
        path: "prayers/:id",
        element: <Prayer />,
      },
      {
        path: "members",
        element: <Members />,
      },
      {
        path: "register/:id",
        element: <Register />,
        loader: getPerson,
      },
    ],
  },
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
