export async function createBookClub(model) {
  const response = await fetch("/api/bookclub", {
    method: "POST",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function createBookClubMeeting(id, model) {
  const response = await fetch(`/api/bookclub/${id}/meeting`, {
    method: "POST",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function deleteBookClub(id) {
  const response = await fetch(`/api/bookclub/${id}`, {
    method: "DELETE",
  });

  if (response.ok) {
    return;
  }

  throw new Error(await response.text());
}

export async function dislikeBookClubMeeting(id) {
  const response = await fetch(`/api/bookclub/${id}/dislike`, {
    method: "PUT",
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function getBookClub(id) {
  const response = await fetch(`/api/bookclub/${id}`);

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function getBookClubs() {
  const response = await fetch("/api/bookclub");

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function likeBookClubMeeting(id) {
  const response = await fetch(`/api/bookclub/${id}/like`, {
    method: "PUT",
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function lookupBookClub(url) {
  const response = await fetch("/api/bookclub/lookup", {
    method: "POST",
    body: JSON.stringify({
      url: url,
    }),
  });

  if (response.ok) {
    const result = await response.text();

    const parser = new DOMParser(),
      doc = parser.parseFromString(result, "text/html"),
      title =
        doc
          .querySelector('meta[property="og:title"]')
          ?.getAttribute("content") ||
        doc.querySelector("title")?.textContent ||
        "(No title)",
      description =
        doc
          .querySelector('meta[property="og:description"]')
          ?.getAttribute("content") ||
        doc
          .querySelector('meta[name="description"]')
          ?.getAttribute("content") ||
        "(No description)",
      image =
        doc
          .querySelector('meta[property="og:image"]')
          ?.getAttribute("content") || "";

    return {
      title: title,
      description: description,
      image: image,
    };
  }
}

export async function remindBookClubMeeting(id) {
  const response = await fetch(`/api/bookclub/${id}/remind`, {
    method: "POST",
  });

  if (response.ok) {
    return;
  }

  throw new Error(await response.text());
}

export async function updateBookClub(model) {
  const response = await fetch(`/api/bookclub/${model.id}`, {
    method: "PUT",
    body: JSON.stringify(model),
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}

export async function voteBookClub(id) {
  const response = await fetch(`/api/bookclub/${id}/vote`, {
    method: "PUT",
  });

  if (response.ok) {
    return await response.json();
  }

  throw new Error(await response.text());
}
