import {
  AddTwoTone,
  CheckTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  ExpandMoreTwoTone,
  NotificationsActiveTwoTone,
  ThumbDownTwoTone,
  ThumbUpTwoTone,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  deleteBookClub,
  dislikeBookClubMeeting,
  likeBookClubMeeting,
  remindBookClubMeeting,
  updateBookClub,
  voteBookClub,
} from "../../api/BookClubApi";
import styled from "@emotion/styled";
import BookClubDialog from "./BookClubDialog";
import ConfirmDelete from "../../cmp/ConfirmDelete";
import MeetingDialog from "./MeetingDialog";

function BookClubCard({
  data,
  allowAdmin,
  allowEdit,
  allowLink,
  allowVote,
  expand,
  username,
  raised = true,
  onDelete,
  onUpdate,
  ...others
}) {
  const [item, setItem] = useState({ ...data }),
    [meeting, setMeeting] = useState(null),
    [busy, setBusy] = useState(false),
    [expanded, setExpanded] = useState(!!expand),
    [edit, setEdit] = useState(null),
    [del, setDel] = useState(null),
    [add, setAdd] = useState(null),
    ExpandMore = styled((props) => {
      const { expand, ...other } = props;
      return <IconButton {...other} />;
    })(({ theme, expand }) => ({
      transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    })),
    getDate = (dateString, options) => {
      const utcDate = new Date(dateString),
        offset = utcDate.getTimezoneOffset(),
        localDate = new Date(utcDate.getTime() + offset * 60000);

      return localDate.toLocaleDateString(undefined, options);
    };

  useEffect(() => {
    if (item.isComplete || !item.meetings || !item.meetings.length) {
      setMeeting(null);
    } else {
      setMeeting(item.meetings[item.meetings.length - 1]);
    }
  }, [item, setMeeting]);

  return (
    <Card raised={raised} {...others}>
      <CardHeader
        action={
          allowEdit &&
          item.id && (
            <Stack direction="row" spacing={1}>
              <IconButton onClick={() => setEdit(item)} title="Edit Book">
                <EditTwoTone />
              </IconButton>
              <IconButton onClick={() => setDel(item)} title="Delete Book">
                <DeleteTwoTone />
              </IconButton>
            </Stack>
          )
        }
        avatar={<Avatar src={item.avatarPath} sx={{ height: 64, width: 64 }} />}
        subheader={item.author}
        title={
          allowLink ? (
            <Link title="Full Screen" to={`/books/${item.id}`}>
              {item.title}
            </Link>
          ) : (
            item.title
          )
        }
      />
      <CardMedia
        component="img"
        image={item.image}
        onClick={() => {
          window.open(item.url, "_blank");
        }}
        sx={{ cursor: "pointer" }}
      />
      {meeting && (
        <CardContent>
          {`Meeting: ${meeting.name} on ${getDate(meeting.date, {
            month: "numeric",
            day: "numeric",
          })}`}
        </CardContent>
      )}
      <CardActions sx={{ mt: 1 }}>
        {item.id && !item.isComplete ? (
          meeting ? (
            <>
              <Badge
                badgeContent={meeting.likes.length}
                color="primary"
                showZero
                title={allowAdmin ? meeting.likes.join(", ") : "Enjoying book"}
              >
                <IconButton
                  disabled={
                    !allowVote || busy || meeting.likes.indexOf(username) > -1
                  }
                  onClick={() => {
                    setBusy(true);

                    likeBookClubMeeting(item.id)
                      .then((result) => {
                        setItem({ ...item, ...result });
                        onUpdate && onUpdate(result);
                      })
                      .finally(() => setBusy(false));
                  }}
                >
                  <ThumbUpTwoTone />
                </IconButton>
              </Badge>
              <Badge
                badgeContent={meeting.dislikes.length}
                color="primary"
                showZero
                title={
                  allowAdmin ? meeting.dislikes.join(", ") : "Not my favorite"
                }
              >
                <IconButton
                  disabled={
                    !allowVote ||
                    busy ||
                    meeting.dislikes.indexOf(username) > -1
                  }
                  onClick={() => {
                    setBusy(true);

                    dislikeBookClubMeeting(item.id)
                      .then((result) => {
                        setItem({ ...item, ...result });
                        onUpdate && onUpdate(result);
                      })
                      .finally(() => setBusy(false));
                  }}
                >
                  <ThumbDownTwoTone />
                </IconButton>
              </Badge>
            </>
          ) : (
            <Badge
              badgeContent={item.likes.length}
              color="primary"
              showZero
              title={allowAdmin ? item.likes.join(", ") : "Vote up"}
            >
              <IconButton
                disabled={
                  !allowVote ||
                  busy ||
                  item.username === username ||
                  item.likes.indexOf(username) > -1
                }
                onClick={() => {
                  setBusy(true);

                  voteBookClub(item.id)
                    .then((result) => {
                      setItem({ ...item, ...result });
                      onUpdate && onUpdate(result);
                    })
                    .finally(() => setBusy(false));
                }}
              >
                <ThumbUpTwoTone />
              </IconButton>
            </Badge>
          )
        ) : null}
        {allowAdmin ? (
          <>
            <IconButton
              disabled={item.isComplete}
              title="Add meeting"
              onClick={() => setAdd(data)}
            >
              <AddTwoTone />
            </IconButton>
            <IconButton
              disabled={!meeting}
              title="Meeting Reminder"
              onClick={() => {
                setBusy(true);
                remindBookClubMeeting(item.id).finally(() => setBusy(false));
              }}
            >
              <NotificationsActiveTwoTone />
            </IconButton>
            <IconButton
              disabled={!meeting}
              title="Finish Book"
              onClick={() => {
                setBusy(true);
                updateBookClub({
                  id: item.id,
                  dateCompleted: new Date(),
                  isComplete: true,
                })
                  .then((result) => {
                    setItem({ ...item, ...result });
                    onUpdate && onUpdate(result);
                  })
                  .finally(() => setBusy(false));
              }}
            >
              <CheckTwoTone />
            </IconButton>
          </>
        ) : null}
        <div style={{ flexGrow: 1 }} />
        <ExpandMore
          expand={expanded}
          onClick={() => {
            setExpanded(!expanded);
          }}
          title={expanded ? "Collapse Description" : "Expand Description"}
        >
          <ExpandMoreTwoTone />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded}>
        <CardContent>{data.description}</CardContent>
      </Collapse>
      {add && (
        <MeetingDialog
          data={data}
          open={!!add}
          onCancel={() => setAdd(null)}
          onUpdate={(update) => {
            setItem({ ...item, ...update });
            onUpdate && onUpdate(update);
            setAdd(null);
          }}
        />
      )}
      {edit && (
        <BookClubDialog
          data={edit}
          open={!!edit}
          onCancel={() => setEdit(null)}
          onUpdate={(update) => {
            setItem({ ...item, ...update });
            onUpdate && onUpdate(update);
            setEdit(null);
          }}
        />
      )}
      {del && (
        <ConfirmDelete
          itemName={del.title}
          onCancel={() => {
            setDel(null);
          }}
          onConfirm={() => {
            deleteBookClub(del.id).then(() => {
              onDelete && onDelete(del.id);
              setDel(null);
            });
          }}
        />
      )}
    </Card>
  );
}

export default BookClubCard;
