import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBook } from "../../api/BookApi";
import { hasRole } from "../../api/UserApi";
import BookNotesDialog from "./BookNotesDialog";
import LessonDialog from "./LessonDialog";
import LessonNotesDialog from "./LessonNotesDialog";
import LessonRecordingDialog from "./LessonRecordingDialog";
import { getLessons } from "../../api/LessonApi";

function LessonList() {
  const [lessons, setLessons] = useState(null),
    [showAdd, setShowAdd] = useState(false),
    [showNotes, setShowNotes] = useState(false),
    [showRecording, setShowRecording] = useState(false),
    [showBookNotes, setShowBookNotes] = useState(false),
    [book, setBook] = useState(null),
    [allowEdit, setAllowEdit] = useState(false),
    { bookId } = useParams(),
    getDate = (dateString) => {
      const utcDate = new Date(dateString),
        offset = utcDate.getTimezoneOffset(),
        localDate = new Date(utcDate.getTime() + offset * 60000);

      return localDate.toLocaleDateString();
    },
    getFilename = (path) => {
      if (path) {
        const parts = path.split("/");

        return parts[parts.length - 1];
      }

      return null;
    },
    handleAdd = () => {
      setShowAdd(true);
    },
    handleAdded = (lesson) => {
      const lessonDate = new Date(lesson.lessonDate);

      let list = lessons.filter((item) => {
        return new Date(item.lessonDate) > lessonDate;
      });

      list.push(lesson);

      list = list.concat(
        lessons.filter((item) => {
          return new Date(item.lessonDate) < lessonDate;
        })
      );

      handleAddClose();
      setLessons(list);
    },
    handleAddClose = () => {
      setShowAdd(false);
    },
    handleBookNotes = () => {
      setShowBookNotes(true);
    },
    handleBookNotesClose = () => {
      setShowBookNotes(false);
    },
    handleNotesClose = () => {
      setShowNotes(false);
    },
    handleRecordingClose = () => {
      setShowRecording(false);
    },
    handleBookUpdate = (record) => {
      setBook({ ...book, ...record });
      handleBookNotesClose();
    },
    handleUpdate = (lesson) => {
      setLessons(
        lessons.map((item) => {
          return lesson.id === item.id ? lesson : item;
        })
      );

      handleNotesClose();
      handleRecordingClose();
    };

  useEffect(() => {
    getLessons(bookId).then((result) => {
      setLessons(result);
    });
  }, [bookId, setLessons]);

  useEffect(() => {
    getBook(bookId).then((record) => {
      setBook(record);
    });
  }, [bookId]);

  useEffect(() => {
    hasRole("leader").then((allow) => {
      setAllowEdit(allow);
    });
  }, [setAllowEdit]);

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        {(allowEdit || (book && book.fullNotesPath)) && (
          <Toolbar>
            {allowEdit && (
              <>
                <Button onClick={handleAdd} variant="contained">
                  Add Lesson
                </Button>
                <Button
                  color="inherit"
                  onClick={handleBookNotes}
                  sx={{ ml: 1 }}
                >
                  Upload Book Notes
                </Button>
              </>
            )}
            {book && book.fullNotesPath && (
              <Typography sx={{ ml: 1 }}>
                Book Notes:
                <a href={book.fullNotesPath} style={{ marginLeft: 8 }}>
                  {getFilename(book.fullNotesPath)}
                </a>
                <a
                  display={book.notesOnlyPath ? "inherit" : "none"}
                  href={book.notesOnlyPath}
                  style={{ marginLeft: 8 }}
                >
                  {getFilename(book.notesOnlyPath)}
                </a>
              </Typography>
            )}
          </Toolbar>
        )}
        <Grid container spacing={2}>
          {lessons ? (
            lessons.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <Card key={item.id} raised>
                  <CardHeader
                    subheader={getDate(item.lessonDate)}
                    title={item.name}
                  />
                  {item.recordingPath && (
                    <CardMedia
                      component="video"
                      autopictureinpicture
                      controls
                      preload="metadata"
                      src={item.recordingPath}
                    />
                  )}
                  <CardContent>
                    {item.fullNotesPath ? (
                      <Typography paragraph>
                        <h5>Notes:</h5>
                        <a href={item.fullNotesPath}>
                          {getFilename(item.fullNotesPath)}
                        </a>
                        <br />
                        <a href={item.notesOnlyPath}>
                          {getFilename(item.notesOnlyPath)}
                        </a>
                      </Typography>
                    ) : (
                      <Typography paragraph>No notes yet.</Typography>
                    )}
                    {allowEdit && (
                      <CardActions>
                        <Button
                          onClick={() => {
                            setShowNotes({
                              id: item.id,
                              bookId: item.bookId,
                            });
                          }}
                        >
                          Upload Notes
                        </Button>
                        <Button
                          onClick={() => {
                            setShowRecording({
                              id: item.id,
                              bookId: item.bookId,
                              name: item.name,
                            });
                          }}
                        >
                          Upload Recording
                        </Button>
                      </CardActions>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Container>
      {showAdd && (
        <LessonDialog
          data={{ bookId: bookId }}
          onUpdate={handleAdded}
          onCancel={handleAddClose}
        />
      )}
      {showNotes && (
        <LessonNotesDialog
          data={showNotes}
          onUpdate={handleUpdate}
          onCancel={handleNotesClose}
        />
      )}
      {showRecording && (
        <LessonRecordingDialog
          data={showRecording}
          onUpdate={handleUpdate}
          onCancel={handleRecordingClose}
        />
      )}
      {showBookNotes && (
        <BookNotesDialog
          data={book}
          onUpdate={handleBookUpdate}
          onCancel={handleBookNotesClose}
        />
      )}
    </>
  );
}

export default LessonList;
