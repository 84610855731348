import { Google, WindowTwoTone } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import { useSearchParams } from "react-router-dom";

function Login() {
    const key = "post_login_redirect_uri",
        [params] = useSearchParams();

    return (
        <Container
            maxWidth="xs"
            sx={{ mt: 5 }}
        >
            <Card
                raised
            >
                <CardHeader
                    title="Restricted Access"
                />
                <CardContent>
                    <Stack
                        spacing={2}
                    >
                        <Button
                            href={`/.auth/login/google?${key}=${params.get(key)}`}
                            startIcon={<Google />}
                            variant="contained"
                        >
                            Login with Google
                        </Button>
                        <Button
                            href={`/.auth/login/aad?${key}=${params.get(key)}`}
                            startIcon={<WindowTwoTone />}
                            variant="contained"
                        >
                            Login with Microsoft
                        </Button>
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}

export default Login;