export async function createBook(model) {
    const response = await fetch("/api/book", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function getBook(id) {
    const response = await fetch(`/api/book/${id}`);

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function getBooks() {
    const response = await fetch("/api/book");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function updateBookNotes(model) {
    const response = await fetch(`/api/book/${model.id}/notes`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}
