import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import { createBookClubMeeting } from "../../api/BookClubApi";

function MeetingDialog({ data, open, onUpdate, onCancel }) {
  const [item, setItem] = useState({ name: "", date: null }),
    [changes, setChanges] = useState({}),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleInputChange = (e) => {
      const key = e.target.name,
        value = e.target.value;

      updateItem(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      createBookClubMeeting(data.id, changes).then((result) => {
        onUpdate && onUpdate(result);
      });
    },
    updateItem = (key, value) => {
      let change = {};

      change[key] = value;
      setItem({ ...item, ...change });
      setChanges({ ...changes, ...change });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Create a Book Club Meeting</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              autoFocus
              fullWidth
              label="Name"
              name="name"
              onChange={handleInputChange}
              required
              sx={{ mt: 1 }}
              value={item.name}
            />
            <TextField
              fullWidth
              label="Meeting date"
              name="date"
              onChange={handleInputChange}
              required
              type="date"
              value={item.date}
            />
            <TextField
              fullWidth
              label="Meeting time"
              name="time"
              onChange={handleInputChange}
              required
              type="time"
              value={item.time}
            />
            <TextField
              fullWidth
              label="Meeting location"
              name="location"
              onChange={handleInputChange}
              required
              value={item.location}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default MeetingDialog;
