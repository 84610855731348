import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { answerPrayer } from "../../api/PrayerApi";

function PrayerAnswerDialog({ data, onUpdate, onCancel }) {
  const [item, setItem] = useState({ ...data }),
    [changes, setChanges] = useState({
      id: data.id,
    }),
    [isBusy, setIsBusy] = useState(false),
    handleClose = (_, reason) => {
      if (reason !== "backdropClick") {
        onCancel && onCancel();
      }
    },
    handleInputChange = (e) => {
      const key = e.target.name,
        value = e.target.value;

      updateItem(key, value);
    },
    handleSubmit = (e) => {
      e.preventDefault();

      setIsBusy(true);

      answerPrayer(changes)
        .then((update) => {
          onUpdate && onUpdate(update);
          setIsBusy(false);
        })
        .catch(() => {
          setIsBusy(false);
        });
    },
    updateItem = (key, value) => {
      let change = {};

      change[key] = value;
      setItem({ ...item, ...change });
      setChanges({ ...changes, ...change });
    };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Record Answered Prayer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Comment (optional)"
            maxRows={10}
            minRows={3}
            multiline
            name="description"
            onChange={handleInputChange}
            sx={{ mt: 1 }}
            value={item.description}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={isBusy} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={isBusy} type="submit" variant="contained">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default PrayerAnswerDialog;
