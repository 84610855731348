import { Alert, AlertTitle, Typography } from "@mui/material";
import React from "react";
import { useRouteError } from "react-router-dom";

function Error() {
    const err = useRouteError();

    return (
        <Alert
            severity="error"
        >
            <AlertTitle>
                Error
            </AlertTitle>
            <Typography
                paragraph
            >
                An unexpected error has occurred.
            </Typography>
            <Typography
                color="text-secondary"
                paragraph
            >
                {err.statusText || err.message}
            </Typography>
        </Alert>
    );
};

export default Error;
