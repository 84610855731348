import { Check, Delete, Edit, ThumbUp } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  deleteQuestion,
  getQuestions,
  voteUpQuestion,
} from "../api/QuestionApi";
import { getUser } from "../api/UserApi";
import QuestionDialog from "./question/QuestionDialog";
import ConfirmDelete from "../cmp/ConfirmDelete";
import SelectQuestionDialog from "./question/SelectQuestionDialog";
import AnswerDialog from "./question/AnswerDialog";

function Questions() {
  const [pending, setPending] = useState(null),
    [answered, setAnswered] = useState(null),
    [allowAdd, setAllowAdd] = useState(false),
    [allowEdit, setAllowEdit] = useState(false),
    [username, setUsername] = useState(null),
    [answerData, setAnswerData] = useState(false),
    [editorData, setEditorData] = useState(false),
    [deleteData, setDeleteData] = useState(false),
    [selectData, setSelectData] = useState(false),
    [isBusy, setIsBusy] = useState(false),
    handleAnswerUpdate = (answer) => {
      setAnswered(
        answered.map((item) => {
          return answer.id === item.id ? answer : item;
        })
      );

      setAnswerData(false);
    },
    handleQuestionAdd = (question) => {
      setPending([...pending, question]);
      setEditorData(false);
    },
    handleQuestionUpdate = (question) => {
      setPending(
        pending
          .map((item) => {
            return item.id === question.id ? question : item;
          })
          .sort((a, b) => {
            const result = b.likes.length - a.likes.length;

            if (result) {
              return result;
            }

            const aDate = new Date(a.dateCreated),
              bDate = new Date(b.dateCreated);

            return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
          })
      );

      setEditorData(false);
    },
    handleDeleteQuestion = () => {
      deleteQuestion(deleteData.id).then(() => {
        setPending(
          pending.filter((item) => {
            return item.id !== deleteData.id;
          })
        );

        setDeleteData(false);
      });
    },
    handleSelectQuestion = (question) => {
      setPending(
        pending.filter((item) => {
          return item.id !== question.id;
        })
      );

      setAnswered([question, ...answered]);

      setSelectData(false);
    },
    getDate = (dateString) => {
      const utcDate = new Date(dateString),
        offset = utcDate.getTimezoneOffset(),
        localDate = new Date(utcDate.getTime() + offset * 60000);

      return localDate.toLocaleDateString();
    },
    getFilename = (path) => {
      const parts = path.split("/");

      return parts[parts.length - 1];
    };

  useEffect(() => {
    getQuestions().then((result) => {
      setPending(result.pending);
      setAnswered(result.answered);
    });
  }, [setPending, setAnswered]);

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setAllowAdd(user.userRoles.indexOf("member") > -1);
        setAllowEdit(user.userRoles.indexOf("leader") > -1);
        setUsername(user.userDetails);
      } else {
        setAllowAdd(false);
        setAllowEdit(false);
        setUsername(null);
      }
    });
  }, [setAllowAdd, setAllowEdit, setUsername]);

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        <Stack spacing={2}>
          <Toolbar>
            <Typography component="h2" variant="h5">
              Question Jar
            </Typography>
            {allowAdd && (
              <Button
                onClick={() => {
                  setEditorData({});
                }}
                sx={{ ml: 2 }}
                variant="contained"
              >
                Add a Question
              </Button>
            )}
          </Toolbar>
          {pending ? (
            pending.length ? (
              <Masonry
                columns={{
                  xs: 1,
                  sm: 2,
                  md: 4,
                }}
                spacing={2}
              >
                {pending.map((item) => (
                  <Card key={item.id} raised>
                    <CardContent title={allowEdit ? item.username : undefined}>
                      <Typography paragraph>{item.description}</Typography>
                    </CardContent>
                    <CardActions>
                      {allowAdd && (
                        <Badge
                          badgeContent={item.likes.length}
                          color="primary"
                          showZero
                          title={allowEdit ? item.likes.join(", ") : "Vote up"}
                        >
                          <IconButton
                            disabled={
                              isBusy ||
                              item.username === username ||
                              item.likes.indexOf(username) > -1
                            }
                            onClick={() => {
                              setIsBusy(true);

                              voteUpQuestion(item.id)
                                .then((question) => {
                                  handleQuestionUpdate(question);
                                  setIsBusy(false);
                                })
                                .catch(() => {
                                  setIsBusy(false);
                                });
                            }}
                          >
                            <ThumbUp />
                          </IconButton>
                        </Badge>
                      )}
                      {(allowEdit || item.username === username) && (
                        <>
                          <IconButton
                            onClick={() => {
                              setEditorData(item);
                            }}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setDeleteData(item);
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </>
                      )}
                      {allowEdit && (
                        <IconButton
                          onClick={() => {
                            setSelectData(item);
                          }}
                        >
                          <Check />
                        </IconButton>
                      )}
                    </CardActions>
                  </Card>
                ))}
              </Masonry>
            ) : (
              <Typography paragraph sx={{ textAlign: "center" }}>
                The Question Jar is empty.
              </Typography>
            )
          ) : (
            <CircularProgress />
          )}
          <Typography component="h2" variant="h5">
            Answered Questions
          </Typography>
          <Masonry
            columns={{
              xs: 1,
              sm: 2,
              md: 4,
            }}
            spacing={2}
          >
            {answered ? (
              answered.map((item) => (
                <Card key={item.id} raised>
                  <CardHeader
                    subheader={`Answered ${getDate(item.dateAnswered)}`}
                    title={item.subject}
                  />
                  {item.recordingPath && (
                    <CardMedia
                      component="video"
                      autopictureinpicture
                      controls
                      preload="metadata"
                      src={item.recordingPath}
                    />
                  )}
                  <CardContent>
                    <Typography paragraph>{item.description}</Typography>
                    {item.notesPath ? (
                      <Typography paragraph>
                        Answer notes:&nbsp;
                        <a href={item.notesPath}>
                          {getFilename(item.notesPath)}
                        </a>
                      </Typography>
                    ) : (
                      <Typography paragraph>No notes yet.</Typography>
                    )}
                  </CardContent>
                  {allowEdit && (
                    <CardActions>
                      <Button
                        onClick={() => {
                          setAnswerData(item);
                        }}
                      >
                        Answer Question
                      </Button>
                    </CardActions>
                  )}
                </Card>
              ))
            ) : (
              <CircularProgress />
            )}
          </Masonry>
        </Stack>
      </Container>
      {answerData && (
        <AnswerDialog
          data={answerData}
          onUpdate={handleAnswerUpdate}
          onCancel={() => {
            setAnswerData(false);
          }}
        />
      )}
      {editorData && (
        <QuestionDialog
          data={editorData}
          onAdd={handleQuestionAdd}
          onUpdate={handleQuestionUpdate}
          onCancel={() => {
            setEditorData(false);
          }}
        />
      )}
      {deleteData && (
        <ConfirmDelete
          itemName="this question"
          onConfirm={handleDeleteQuestion}
          onCancel={() => {
            setDeleteData(false);
          }}
        />
      )}
      {selectData && (
        <SelectQuestionDialog
          data={selectData}
          onUpdate={handleSelectQuestion}
          onCancel={() => {
            setSelectData(false);
          }}
        />
      )}
    </>
  );
}

export default Questions;
