export async function createLesson(model) {
    const response = await fetch(`/api/book/${model.bookId}/lesson`, {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function getLessons(bookId) {
    const response = await fetch(`/api/book/${bookId}/lesson`);

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function updateLessonNotes(model) {
    const response = await fetch(`/api/book/${model.bookId}/lesson/${model.id}/notes`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function updateLessonRecording(model) {
    const response = await fetch(`/api/book/${model.bookId}/lesson/${model.id}/recording`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}
