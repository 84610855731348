import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getBooks } from "../../api/BookApi";

function LessonIndex() {
    const nav = useNavigate();

    useEffect(() => {
        let id, date;

        getBooks()
            .then(books => {
                books.forEach(item => {
                    if (item.latestLessonDate) {
                        if (id) {
                            if (new Date(item.latestLessonDate) > date) {
                                id = item.id;
                                date = new Date(item.latestLessonDate);
                            }
                        }
                        else {
                            id = item.id;
                            date = new Date(item.latestLessonDate);
                        }
                    }
                });

                if (id) {
                    nav(id);
                }
            });
    }, [nav]);

    return (
        <></>
    )
}

export default LessonIndex;