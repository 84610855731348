import { AnonymousCredential, BlockBlobClient } from "@azure/storage-blob";

let token = null;

export async function uploadFile(folder, file) {
    await initToken(folder);

    const href = `${token.url}/${folder}/${file.name}`,
        login = `${href}?${token.sasKey}`,
        client = new BlockBlobClient(login, new AnonymousCredential());

    await client.uploadData(file);
    return href;
}

async function initToken(folder) {
    const response = await fetch(`/api/upload/${folder}`);

    if (response.ok) {
        token = await response.json();
        token.expires = new Date(token.expires);

        return;
    }

    throw new Error(await response.text());
}
