let userCache = null;

export async function getUser() {
    if (userCache) {
        return userCache;
    }

    const response = await fetch("/me");

    if (response.ok) {
        const result = await response.json();

        userCache = result.clientPrincipal;
        return result.clientPrincipal;
    }

    throw new Error(await response.text());
}

export async function hasRole(role) {
    const user = await getUser();

    if (user) {
        return user.userRoles.indexOf(role) > -1;
    }

    return false;
}